import { Redline } from 'redline-client-sdk';
import { RLUserTrackingEvents } from '../types';
import { pixel } from './pixel';

export const userTrackingEvents = (rl: Redline): RLUserTrackingEvents => ({
  elementClicked: (payload = {}) => {
    const event = 'userTracking.ElementClicked.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
  },
  elementViewed: (payload = {}) => {
    const event = 'userTracking.ElementViewed.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
  },
  fieldInputted: (payload = {}) => {
    const event = 'userTracking.FieldInputted.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
  },
  fieldSelected: (payload = {}) => {
    const event = 'userTracking.FieldSelected.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
  },
  formOutcomeReceived: (payload = {}) => {
    const event = 'userTracking.FormOutcomeReceived.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
  },
  formStarted: (payload = {}) => {
    const event = 'userTracking.FormStarted.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
  },
  formSubmitted: (payload = {}) => {
    const event = 'userTracking.FormSubmitted.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
  },
  formContinued: (payload = {}) => {
    const event = 'userTracking.FormContinued.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
  },
  formBacked: (payload = {}) => {
    const event = 'userTracking.FormBacked.v1';
    rl.track(event, { event, ...payload });
  },
  formErrored: (payload = {}) => {
    const event = 'userTracking.FormErrored.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
  },
  experimentStarted: (payload = {}) => {
    const event = 'userTracking.ExperimentStarted.v1';
    rl.track(event, { ...payload });
  },
});
