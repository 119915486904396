import { useEffect, useState } from 'react';
import { TrackProps } from '../types';
import safestorage from '@libs/utils/helpers/getSafeStorage';
import {
  addCookie,
  removeWithKeyIncludes,
} from '@libs/utils/helpers/getSafeCookies';
import { getDevice } from '@libs/utils/helpers/getDevice';
import { getParamsUrl } from '@libs/utils/helpers/getParamsUrl';
import { addToDataLayer } from '@libs/utils/helpers/addToDataLayer';
import { getSession, getUtm, getGclid } from '@libs/utils/helpers/getMood';
import { getFbc, getFbp } from '@libs/utils/helpers/getFacebookPixel';
import untilLoad from '@libs/utils/helpers/untilLoad';
import { useConstants } from './useConstants';

const useTrack = () => {
  const eventNames = useConstants();
  const [uuid, setUuid] = useState<string>();
  const [indicativeReady, setIndicativeReady] = useState<boolean>(false);
  const indicativeCookieUtmExpires = 365;
  const indicativeCookieExpires = 15;

  const track: TrackProps = async (name, params) => {
    await untilLoad(() => (window as any).mood);
    
    //TODO refactor

    const utm = getUtm();
    addToDataLayer({
      event: name,
      utm_source: utm?.source,
      utm_medium: utm?.medium,
      utm_campaign: utm?.campaign,
      utm_term: utm?.term,
      utm_content: utm?.content,
      utm_cluster: utm?.cluster,
      utm_sou: utm?.sou,
      gclid: getGclid(),
      fcb: getFbc(),
      fbp: getFbp(),
      ['ac.initialReferrer']: getSession()?.initialReferrer,
    });
    
    if (!indicativeReady) {
      setIndicativeReady(true);
    }
  };

  const getCurretCustomerId = () => {

    safestorage.getItem<string>('CUSTOMER_ID_HASH').then((customerIdHash) => {
      

      if (customerIdHash) {
        const expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + indicativeCookieExpires);

        // TODO: check if this is necessary
        // const indicativeInitParams = indicative._handleInitParams();
        // console.log('indicativeInitParams', indicativeInitParams);
        // if (
        //   indicativeInitParams &&
        //   indicativeInitParams.sessionsThreshold &&
        //   typeof indicativeInitParams.sessionsThreshold == 'number'
        // ) {
        //   expireDate = indicativeInitParams.sessionsThreshold / 24 / 60; // in days
        // }

        addCookie({
          key: 'IND_LST',
          value: customerIdHash || uuid || '',
          encrypt: true,
          expires: expireDate,
        });

        addToDataLayer({
          customerIdHash,
        });
      } else {
        clearControls();
      }
    });
  };


  const setCampaignParams = () => {
    const params = getParamsUrl(window.location.search);
    const paramsArrKeys = Object.keys(params);
    const isAcceptParams = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
      'utm_cluster',
      'utm_sou',
    ];

    const filterdParams = paramsArrKeys.reduce((acc, param) => {
      return isAcceptParams.includes(param)
        ? { ...acc, [param]: params[param] }
        : acc;
    }, {});

    if (paramsArrKeys.length > 0) {
      const utmMap = JSON.stringify(params);
      const expireDateUtm = new Date();
      expireDateUtm.setDate(
        expireDateUtm.getDate() + indicativeCookieUtmExpires
      );

      addCookie({
        key: 'IND_UTM',
        value: utmMap,
        encrypt: true,
        expires: expireDateUtm,
      });
    }

    const cookiesUtm = getUtm();
    const cookiesUtmArrKeys = Object.keys(cookiesUtm || {});
    const formatedUtm = cookiesUtmArrKeys?.reduce((acc, utm) => {
      return cookiesUtm && cookiesUtm[utm]
        ? { ...acc, [`utm_${utm}`]: cookiesUtm[utm] }
        : acc;
    }, {});

    return Object.keys(filterdParams).length ? filterdParams : formatedUtm;
  };

  const clearControls = () => {
    setUuid('');
  };

  useEffect(() => {
    setCampaignParams();
  }, []);

  useEffect(() => {
    getCurretCustomerId();
  }, [indicativeReady]);

  return track;
};

export default useTrack;
