import { create } from 'zustand';
import {
  devtools,
} from 'zustand/middleware';

type UseHidePageTemplate = {
  shouldHidePageTemplate: boolean;
  hidePageTemplate: () => void;
  showPageTemplate: () => void;
}

export const useHidePageTemplate = create<UseHidePageTemplate>()(
  devtools(
    (set) => ({
      shouldHidePageTemplate: false,
      hidePageTemplate: () => set({ shouldHidePageTemplate: true }),
      showPageTemplate: () => set({ shouldHidePageTemplate: false }),
    })
  )
);
