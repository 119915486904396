import React from 'react';

interface ImagemProps {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  loading?: 'lazy' | 'eager' | undefined;
  className?: string;
}

const Img: React.FC<ImagemProps> = ({
  src,
  alt,
  width,
  height,
  loading = 'lazy',
  className,
}) => {
  return (
    <img
      className={className}
      src={src}
      alt={alt}
      width={width}
      height={height}
      loading={loading}
    />
  );
};

export default Img;
