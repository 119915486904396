import { useContext } from 'react';
import { TrackingContext } from '../components/TrackingProvider';

export function useTracking() {
  const trackinContext = useContext(TrackingContext);

  if (trackinContext === undefined) {
    throw new Error('useTracking can only be used in a Component');
  }

  return {
    ...trackinContext,
  };
}
