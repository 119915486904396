import useApiFinancialData from '@common/apis/financial-data/useApiFinancialData';
import useApiSortingHat from '@common/apis/sorting-hat/useApiSortingHat';
import { useEffect, useState } from 'react';
import { OfferDecision, OfferProps } from './types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserStore } from '@common/store/useUserStore';

import { useStoryblokDatasource } from '@libs/storyblok';

import { offerCategory } from '@common/constants/offerCategory';
import { pageNames } from '@common/routes/pageNames';
import useApiFirebolt from '@common/apis/firebolt/useApiFirebolt';
import useTrackOffer from '../useTrackOffer';
import { getDevice } from '@libs/utils/helpers/getDevice';
import { getSessionID } from 'redline-client-sdk';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';
import { isIOS } from '@libs/utils/helpers/mobileOS';
import { isSafari } from '@libs/utils/helpers/isSafari';
import { addCookie } from '@libs/utils/helpers/getSafeCookies';
import { getSiteIdParam } from '@libs/utils/helpers/getSiteIdParam';
import { getDeliveryIdParam } from '@libs/utils/helpers/getDeliveryIdParam';
import { getUFDigitFromCPF } from '@libs/utils/helpers/getUFDigitFromCPF';
import { getRDG } from '@libs/utils/helpers/getRDG';
import { getDDD } from '@libs/utils/helpers/getDDD';
import constants from '@common/constants';
import { getFbAvailableCookies } from '@libs/utils/helpers/getFacebookPixel';
import { getGAAvailableCookies } from '@libs/utils/helpers/getGoogleAnalyticsCookies';
import {
  addAutofillParam,
  FireboltUserInfo,
} from '@libs/utils/helpers/addAutofillParam';
import { isProd } from '@libs/utils/helpers/isProd';

const useGetOffers = () => {
  const loansCookieKey: { [key: string]: string } = {
    'loans-simplic-ep': constants.loans.canAccessSimplicIntegratedFlow,
    'loans-noverde-ep': constants.loans.canAccessNoverdeIntegratedFlow,
    'loans-supersim-ep': constants.loans.canAccessSupersimIntegratedFlow,
  };

  const navigate = useNavigate();
  const {
    trackProductViewed,
    trackDecisionEnriched,
    trackDecisionRequested,
    trackDecisionOutcomeReceived,
    trackProposalStarted,
    trackElementClicked,
  } = useTrackOffer();
  const { apiSortingHatDecide, apiSortingHatTrack } = useApiSortingHat();
  const { sendSoftProposal } = useApiFirebolt();
  const { apiFinancialDataNegativeDebts } = useApiFinancialData();
  const { trackProductClicked, trackRedirectRequested } = useTrackOffer();
  const {
    user,
    addSortingHat: addSortingHatStore,
    sortingHat: sortingHatStore,
  } = useUserStore((state) => state);
  const [userNegative, setUserNegative] = useState<boolean>(false);
  const [featuredOffers, setFeaturedOffers] = useState<OfferProps[]>([]);
  const [preApprovedOffers, setPreApprovedOffers] = useState<OfferProps[]>([]);
  const [filteredOffers, setFilteredOffers] = useState<OfferProps[]>([]);
  const [offers, setOffers] = useState<OfferProps[]>([]);
  const [openDigioModal, setOpenDigioModal] = useState(false);
  const [decideFinish, setDecideFinish] = useState<boolean>(false);

  const { getDatasource } = useStoryblokDatasource();

  const location = useLocation();

  const initialCategory = location?.state?.key
    ? location.state.key
    : offerCategory.ALL;

  const getSortingHat = () => {
    loadNegativate();
  };

  const loadNegativate = () => {
    apiFinancialDataNegativeDebts
      .send()
      .then((response) => {
        setUserNegative(response?.data?.negativeDebts.length > 0);
        handleTrack(userNegative);
      })
      .catch((err) => {
        console.error('[ERROR LOAD NEGATIVATE]', err);
        handleTrack();
      });
  };

  const handleTrack = (badCredit?: boolean) => {
    const trackRequest = {
      document: user.documento,
      customerIdHash: user.customerIdHash,
      badCredit: badCredit,
      device: getDevice(),
      fullName: user.nome,
      email: user.email,
      mainPhone: user.celular,
      sessionId: getSessionID(),
      cpfUfEmissor: getUFDigitFromCPF(user.documento),
      ddd: getDDD(user.celular),
      rdg: getRDG(user.documento),
    };
    trackDecisionEnriched();
    apiSortingHatTrack
      .send(trackRequest as any)
      .then(() => {
        setTimeout(() => getDecisions(), 3000);
      })
      .catch((err) => {
        console.error('[ERROR SH TRACK]', err);
      });
  };

  const getDecisions = () => {
    trackDecisionRequested();
    apiSortingHatDecide
      .send({
        document: user.documento,
        customerIdHash: user.customerIdHash,
        device: getDevice(),
      })
      .then((res) => {
        const payload = { acTree: res };
        addSortingHatStore(payload);

        const { decisions } = res.result;

        decisions.forEach((decision) => {
          const loansPartners = [
            'loans-simplic-ep',
            'loans-noverde-ep',
            'loans-supersim-ep',
          ];
          if (loansPartners.includes(decision.slug)) {
            addCookie({
              key: loansCookieKey[decision.slug],
              value: 'true',
              encrypt: false,
            });
          }
        });

        trackDecisionOutcomeReceived({ decisions });
        setDecideFinish(true);
      })
      .catch((err) => {
        console.error('[ERROR GETTING DECISIONS]', err);
      });
  };

  const createOfferObjects = (decisions: OfferDecision[] = []) => {
    return decisions
      ?.map((decision) => {
        const storyblokContent = decision?.metadata?.content;
        if (!storyblokContent) {
          console.error(`Missing storyblok data for decision ${decision.slug}`);
          return {} as OfferProps;
        } else {
          const offers: OfferProps = {
            content: storyblokContent as any, // TODO: need refactor
            shDecision: decision,
          };
          return offers;
        }
      })
      .filter((offers) => !!offers.content);
  };

  const setStateOffers = (offers: OfferProps[]) => {
    const filteredFeaturedOffers = filterByType(offers, 'featured');
    const filteredPreAapprovedOffers = filterByPath(filteredFeaturedOffers);
    const filteredFeaturedByPath = filterByPath(filteredFeaturedOffers);

    setOffers(offers);
    setFeaturedOffers(filteredFeaturedByPath);
    setPreApprovedOffers(filteredPreAapprovedOffers);
  };

  const filterByPath = (offers: OfferProps[]) => {
    const filteredResult = [
      'ac-destaque-dashboard',
      'ac-destaque-ofertas',
      'ac-monitoramento-financeiro-destaque-ofertas',
      'ac-ofertas-pre-aprovadas-lista-ofertas',
    ].reduce((acc: any, curr: string) => {
      const filteredSlug = offers.filter(({ shDecision }) => {
        const safeFeatured = Array.isArray(shDecision['featured'])
          ? shDecision['featured']
          : [];
        return safeFeatured?.includes(curr);
      });

      return acc
        ? {
            ...acc,
            [curr]: filteredSlug,
          }
        : acc;
    }, {});

    const slugsByPath: Record<string, string> = {
      '/minhas-dividas': 'ac-destaque-dashboard',
      '/ofertas': 'ac-destaque-ofertas',
      '/ofertas/ofertas-pre-aprovadas':
        'ac-ofertas-pre-aprovadas-lista-ofertas',
      '/monitoramento/financeiro':
        'ac-monitoramento-financeiro-destaque-ofertas',
    };

    return filteredResult[slugsByPath[location.pathname]];
  };

  const filterByType = (offers: OfferProps[], type: string) => {
    return offers.filter(({ shDecision }) => shDecision[type]);
  };

  const filterByCategory = (categoryToFilter: string) => {
    if (categoryToFilter === offerCategory.ALL) {
      setFilteredOffers(offers);
      return offers;
    } else {
      const filtered = offers.filter(
        ({ content }) => content?.category === categoryToFilter
      );
      setFilteredOffers(filtered);
      return filtered;
    }
  };

  const numberOfOffers = (exception?: string) => {
    if (exception) {
      const offersWithExceptions = offers?.filter(
        ({ shDecision }) => !shDecision?.[exception]
      );

      return offersWithExceptions.length;
    } else {
      return offers.length;
    }
  };

  function redirectToOffers() {
    trackElementClicked({
      location: 'ac-minhas-dividas-destaque-ofertas',
      elementType: 'button',
      name: pageNames.offers.path,
      text: 'Ver ofertas',
    });
    navigate(pageNames.offers.path, { state: { key: offerCategory.ALL } });
  }

  function onCloseDigioModal() {
    setOpenDigioModal(false);
  }

  const onClickOffer = async (
    offer: OfferProps,
    location: string,
    index: number
  ) => {
    const { shDecision, content } = offer;

    const isDifferentFlow = await handleDifferentFlows({
      ...offer,
      location,
      index,
    });
    if (isDifferentFlow) return null;

    const proposalSoft = await sendProposal(
      shDecision.partner,
      shDecision.slug
    );
    const fireboltId = proposalSoft?.firebolt_id || '';
    trackProductClicked(offer, location, index, fireboltId);

    let url = await getUrl(content?.links, shDecision?.preApproved, fireboltId);

    if (url) {
      await sendEventProposalStarted(offer.content, url, location, fireboltId);
      if (shDecision.slug === 'cards-neon') {
        return url && redirectToOfferNeon(offer, location, url, proposalSoft);
      }

      if (offer?.content?.full_funnel) {
        const userInfo: FireboltUserInfo = {
          cpf: user?.documento,
          fullName: user?.nome,
          email: user?.email,
          phone: user?.celular,
        };

        // FIXME: Temp solution to redirect to Juvo
        // After the implementation of the new Juvo page, we can trust in storyblok link
        let tempJuvoUrl = url;
        if (!url.includes('/produtos/juvo')) {
          tempJuvoUrl = isProd()
            ? 'https://acordocerto.com.br/produtos/juvo'
            : 'https://dev.acordocerto.com.br/produtos/juvo';
        }

        url = addAutofillParam({
          productSlug: shDecision.slug,
          url: tempJuvoUrl,
          user: userInfo,
        });
      }

      redirect(url);
      trackRedirectRequested(offer, location, url, fireboltId);
    }
  };

  const handleDifferentFlows = async (
    offer: OfferProps & { location: string; index: number }
  ) => {
    const { shDecision, content, location, index } = offer;
    const url = await getUrl(content?.links, shDecision?.preApproved, '');

    switch (shDecision.slug) {
      case 'cards-digio':
        setOpenDigioModal(true);
        return true;
      case 'cards-bancodobrasil-ourocardfacil':
        if (url) {
          trackProductClicked(offer, location, index, '');
          trackRedirectRequested(offer, location, url, '');
          redirect(url);
        }
        return true;
    }
  };

  const redirectToOfferNeon = async (
    offer: OfferProps,
    location: string,
    url: string,
    fireboltInfo: any
  ) => {
    const redirectUrls = fireboltInfo.webhookResult.processedData as Partial<{
      storyblok_redirect_urls: { param: string; redirect: string }[];
    }>;
    const acOrganicUrl = redirectUrls.storyblok_redirect_urls?.find(
      (url) => url.param === 'ac_organic'
    );

    if (acOrganicUrl) {
      window.open(acOrganicUrl.redirect, '_blank');
      trackRedirectRequested(
        offer,
        location,
        acOrganicUrl.redirect,
        fireboltInfo?.firebolt_id
      );
    } else {
      redirect(url);
      trackRedirectRequested(offer, location, url, fireboltInfo?.firebolt_id);
    }
  };

  async function sendProposal(partnerSlug: string, productSlug: string) {
    const { customerIdHash } = user;
    const payload = {
      item: {
        cpf: user?.documento,
        full_name: user?.nome,
        email: user?.email,
        main_phone: user?.celular,
        date_of_birth: user?.dataNascimento,
        choosen_card: partnerSlug,
        issuer: partnerSlug,
        product_slug: productSlug,
      },
      metadata: {
        start_source_url: getCookie('start_source_url'),
        anonymousId: customerIdHash,
        sessionId: getSessionID(),
        fbCookies: {
          fcbId: getFbAvailableCookies().fbc,
          fbpId: getFbAvailableCookies().fbp,
        },
        gaCookies: {
          gaId: getGAAvailableCookies().ga,
        },
      },
    };

    try {
      const response = await sendSoftProposal.send(partnerSlug, payload);
      return response;
    } catch (error) {
      console.error('falha na requisição firebolt soft', error);
    }
  }

  const getUrl = (links: any, isPreApproved: boolean, fireboltId: string) => {
    const linksAC = links.find((link: any) => link.application === 'ac-links');

    if (!linksAC) return;

    const preApprovedLink = userNegative
      ? linksAC?.link_pre_approved_negative
      : linksAC?.link_pre_approved;
    const defaultLink = userNegative
      ? linksAC?.link_with_debts
      : linksAC?.link_without_debts;
    const link = isPreApproved ? preApprovedLink : defaultLink;
    const urlWithParams = fillUrlParameters(link?.url, fireboltId);

    return urlWithParams;
  };

  const fillUrlParameters = async (url: string, fireboltId: string) => {
    const { customerIdHash } = user;
    const startSourceUrl = getCookie('start_source_url') || '';
    const deliveryId = getDeliveryIdParam({ startSourceUrl });
    const siteId =
      (await getSiteIdParam({
        marketplace: 'ac',
        startSourceUrl,
        getDatasource,
      })) || '';

    url = url
      .replace(/\(firebolt_id\)/g, fireboltId)
      .replace('(af_siteid)', siteId)
      .replace('(delivery_id)', deliveryId);
    if (url.includes('noverde')) {
      url = url.replace('(utm_term)', customerIdHash);
    }

    const urlObj = new URL(url);
    const searchParams = urlObj.searchParams;

    const filledParams: Record<string, string> = {
      anonymous_id: customerIdHash + Date.now(),
      session_id: customerIdHash + Date.now(),
      aff_unique2: customerIdHash,
      clickID: customerIdHash + 'T' + Date.now(),
      firebolt_id: fireboltId,
    };

    Object.keys(filledParams).forEach((chave) => {
      if (searchParams.has(chave)) {
        searchParams.set(chave, filledParams[chave]);
      }
    });

    return urlObj.toString();
  };

  const sendEventProductViewed = (
    inViewport: boolean,
    enterCount: number,
    offer: OfferProps = {} as OfferProps,
    index: number,
    location: string
  ) => {
    if (!offer || !offer?.content || !offer?.shDecision) return null;

    const firstTimeViewed = inViewport && enterCount === 1;
    if (firstTimeViewed) {
      const { content, shDecision } = offer;
      const { slug, title, category, partner, variant } = content;
      const { limit } = shDecision;
      const payload = {
        slug,
        category,
        variant,
        name: title,
        brand: partner,
        price: limit,
        position: index,
        location: location,
      };

      trackProductViewed(payload);
    }
  };

  const sendEventProposalStarted = async (
    contentOffer: OfferProps['content'],
    redirectUrl: string,
    location: string,
    fireboltId: string
  ) => {
    if (!contentOffer) return;
    const { slug, category } = contentOffer;

    if (contentOffer?.full_funnel) return;

    const payload = {
      initialSlug: slug,
      finalSlug: slug,
      category,
      redirectUrl,
      location,
      fireboltId,
    };
    await trackProposalStarted(payload);
  };

  const redirect = (url: string) => {
    if (isIOS() || isSafari()) {
      window.location.href = url;
    } else {
      window.open(url, '_blank');
    }
  };

  useEffect(() => {
    filterByCategory(initialCategory);
  }, [offers]);

  useEffect(() => {
    if (sortingHatStore.acTree?.enabled) {
      const offers = createOfferObjects(
        sortingHatStore.acTree.result.decisions
      );
      offers && setStateOffers(offers);
    }
  }, [sortingHatStore]);

  return {
    getSortingHat,
    offers,
    featuredOffers,
    preApprovedOffers,
    userNegative,
    filterByCategory,
    filteredOffers,
    loadNegativate,
    initialCategory,
    numberOfOffers,
    onClickOffer,
    redirectToOffers,
    onCloseDigioModal,
    openDigioModal,
    sendEventProductViewed,
    decideFinish,
  };
};
export default useGetOffers;
