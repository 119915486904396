// import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Text from '@libs/atlas/components/Text';
import Button from '@libs/atlas/components/Button';
import Conditional from '@libs/atlas/components/misc/Conditional';
import { dateToLocale } from '@libs/utils/helpers/dateToLocale';

import {
  // IconCreditCard,
  IconLoader,
  // IconMoney,
  IconVelocimeter,
  IconX,
} from '../icons/default';

import NotificationsList from './NotificationsList';
import './styles.scss';

interface NotificationsBarProps {
  notifications: Record<string, any>[];
  notificationsLoader: boolean;
  notificationsLength: number;
  toggleNotifications: boolean;
  onClickNotification: (url: string) => (event: any) => void;
  onClose: () => void;
  afterCTASlot?: React.ReactNode;
}

const NotificationsBar = ({
  notifications,
  notificationsLength,
  notificationsLoader,
  toggleNotifications,
  onClickNotification,
  onClose,
  afterCTASlot,
}: NotificationsBarProps) => {
  const notificationsBarClasses = classNames('notifications-bar', {
    'is-active': toggleNotifications,
    'is-loader': notificationsLoader,
  });

  return (
    <div className={notificationsBarClasses} onClick={onClose}>
      <div className="notifications-bar__menu">
        <div className="notifications-bar__actions">
          <Text as="h2" color="common" variant="heading-three" weight="bold">
            Lembretes
          </Text>
          <button onClick={onClose} className="navbar__actions-close" aria-label="Fechar notificações">
            <IconX />
          </button>
        </div>
        <div className="notifications-bar__body">
          <div className="notifications-bar__links">
            <a
              href={'/monitoramento/financeiro'}
              className="notifications-bar__link"
            >
              <div className="notifications-bar__icon notifications-bar__icon--score">
                <IconVelocimeter color="dark" />
              </div>
              <Text as="span" color="common" variant="body-large" weight="bold">
                Você já viu seu Score?
              </Text>
              <Text
                as="span"
                color="common"
                variant="body-large"
                weight="regular"
              >
                Veja agora sua pontuação aqui mesmo na Acordo Certo.
              </Text>
              <Text
                as="span"
                color="common"
                variant="body-small"
                weight="regular"
              >
                {dateToLocale()}
              </Text>
            </a>

            <Conditional
              condition={notificationsLength > 0}
              renderIf={
                <NotificationsList
                  notifications={notifications}
                  onClickNotification={onClickNotification}
                />
              }
            />
          </div>
          <div className="notifications-bar__loader">
            <IconLoader />
          </div>
        </div>
        <div className="notifications-bar__bottom">
          <Button expand="x" onClick={onClose}>
            Ok, entendi
          </Button>
        </div>
        <div className="notifications-bar__after-cta-slot">{afterCTASlot}</div>
      </div>
    </div>
  );
};

export default NotificationsBar;
