interface PageNames {
  [key: string]: {
    path: string;
    type: string;
    name: string;
  };
}

export const pageNames: PageNames = {
  home: {
    path: '/',
    type: 'public',
    name: 'Home',
  },
  about: {
    path: '/about',
    type: 'public',
    name: 'Sobre',
  },
  signin: {
    path: '/login',
    type: 'public',
    name: 'Login',
  },
  inplicitSignin: {
    path: '/login-consumidor-positivo',
    type: 'public',
    name: 'Login Consumidor Positivo',
  },
  signup: {
    path: '/cadastro',
    type: 'public',
    name: 'Cadastro',
  },
  recoveryPassword: {
    path: '/recuperar-senha',
    type: 'public',
    name: 'Recuperar Senha',
  },
  redefinePassword: {
    path: '/redefinir-senha',
    type: 'public',
    name: 'Redefinir senha',
  },
  errorNoData: {
    path: '/cadastro/erro-faltando-informacoes',
    type: 'public',
    name: 'Erro No Data',
  },
  errorMinor: {
    path: '/cadastro/erro-menor-de-idade',
    type: 'public',
    name: 'Error Minor',
  },
  errorPublicPerson: {
    path: '/cadastro/erro-pessoa-publicamente-exposta',
    type: 'public',
    name: 'Error Public Person',
  },
  errorKba: {
    path: '/cadastro/erro-kba-limite',
    type: 'public',
    name: 'Error Kba',
  },
  errorKbaBigDataCorp: {
    path: '/cadastro/erro-kba-invalido',
    type: 'public',
    name: 'Error Kba Recovery Password',
  },
  account: {
    path: '/minha-conta',
    type: 'private',
    name: 'Minha Conta',
  },
  myDebts: {
    path: '/minhas-dividas',
    type: 'private',
    name: 'Minhas Dívidas',
  },
  confirmation: {
    path: '/:partner/confirmacao',
    type: 'private',
    name: 'Confirmação'
  },
  agreementClosed: {
    path: '/:partner/acordo-fechado',
    type: 'private',
    name: 'Acordo Fechado'
  },
  agreementClosedError: {
    path: '/:partner/acordo-fechado/erro',
    type: 'private',
    name: 'Erro ao fechar o acordo'
  },
  offers: {
    path: '/ofertas',
    type: 'private',
    name: 'Ofertas',
  },
  preApprovedOffers: {
    path: '/ofertas/ofertas-pre-aprovadas',
    type: 'private',
    name: 'Ofertas desbloqueadas'
  },
  loans: {
    path: '/emprestimos',
    type: 'private',
    name: 'Simulador de Empréstimo',
  },
  myAgreements: {
    path: '/meus-acordos',
    type: 'private',
    name: 'Meus Acordos',
  },
  myAgreementsDetail: {
    path: '/meus-acordos/acordo/:agreementId',
    type: 'private',
    name: 'Detalhe do Acordo',
  },
  myAgreementsDetailConditions: {
    path: '/meus-acordos/acordo/:agreementId/condicoes',
    type: 'private',
    name: 'Condições do Acordo',
  },
  myAgreementsDetailCompositions: {
    path: '/meus-acordos/acordo/:agreementId/composicao',
    type: 'private',
    name: 'Composição do Acordo',
  },
  myScore: {
    path: '/monitoramento/financeiro',
    type: 'private',
    name: 'Financeiro',
  },
  scoreKbaQuestions: {
    path: '/monitoramento/score',
    type: 'private',
    name: 'Score',
  },
  scoreError: {
    path: '/monitoramento/erro',
    type: 'private',
    name: 'Score Error',
  },
  accountCanceled: {
    path: '/conta-cancelada',
    type: 'public',
    name: 'Conta Cancelada',
  },
  notFound: {
    path: '/pagina-nao-encontrada',
    type: 'public',
    name: 'Página não encontrada',
  },
};

export const publicPagePaths = Object.keys(pageNames).reduce(
  (acc: string[], curr: string) => {
    return pageNames[curr].type === 'public'
      ? acc.concat(pageNames[curr].path)
      : acc;
  },
  []
);

export const privatePagePaths = Object.keys(pageNames).reduce(
  (acc: string[], curr: string) => {
    return pageNames[curr].type === 'private'
      ? acc.concat(pageNames[curr].path)
      : acc;
  },
  []
);
