import { addToDataLayer } from '@libs/utils/helpers/addToDataLayer';

import { useUserStore } from '@common/store/useUserStore';
import useEngageTrack from '@common/hooks/useEngageTrack';
import {
  SortingDebtResult,
  PartnerSystems,
} from '@common/apis/sorting-debt/types';
import {
  legacyBase64Decrypt,
  legacyBase64Encrypt,
} from '@libs/utils/helpers/base64';
import { useTracking } from '@libs/tracking';

type DebtEvent = 'debtFound' | 'simulationDone' | 'debtViewed';

type StoredDebtsEvent = {
  [key: string]: {
    creditor: string;
    sentTheEvent: string;
  };
};

const useTrackDebtCarousel = () => {
  const { trackSimulationDone, trackDebtViewed, trackDebtFound } = useTracking();
  const { sendDebtLocatedEvent, sendDebtVisualizedEvent } = useEngageTrack();
  const { user } = useUserStore((state) => state);

  const currentDate = new Date();
  const debtEventName = {
    debtFound: 'DEBTS_FOUND',
    debtViewed: 'DEBTS_VIEWED',
    simulationDone: 'SIMULATION_DONE',
  };

  const addGtagEvent = (event: string) => {
    addToDataLayer({
      event,
    });
  };

  const hasSentDebtEvent = (
    fullId: string,
    storedDebtsEvent: StoredDebtsEvent
  ) => {
    currentDate.setHours(0, 0, 0, 0);
    const hasSentDate = new Date(storedDebtsEvent?.[fullId]?.sentTheEvent);
    const isValidStoredDebtsEvent =
      storedDebtsEvent && currentDate > hasSentDate;

    return !storedDebtsEvent?.[fullId] || isValidStoredDebtsEvent;
  };

  const sendEngageTrackEvent = (event: string, debt: SortingDebtResult) => {
    if (event === 'debtFound') {
      sendDebtLocatedEvent();
    }

    if (event === 'debtViewed') {
      const [paymentOpt] = debt.paymentOptions;

      sendDebtVisualizedEvent({
        partner: debt?.partner?.identifier,
        original_company: debt?.partner?.partnerIdentifier,
        value: paymentOpt.agreementValue,
      });
    }
  };

  const addDebtToStorage = (
    storedDebtsEvent: StoredDebtsEvent,
    debtId: string,
    partnerId: string,
    eventName: string
  ) => {
    const debtStorageItem = {
      [debtId]: {
        sentTheEvent: currentDate,
        creditor: partnerId,
      },
    };

    const debtsKeyStorage = legacyBase64Encrypt(
      JSON.stringify(
        storedDebtsEvent
          ? { ...storedDebtsEvent, ...debtStorageItem }
          : debtStorageItem
      )
    );

    localStorage.setItem(eventName, debtsKeyStorage);
  };

  const sendEvent = (debt: any, debtId: string, event: DebtEvent) => {
    const partnerIdentifier = debt?.partner?.identifier || debt?.identifier;
    if (!partnerIdentifier) return;

    const partnerId = debt?.partner?.id || debt?.id;
    const bvsNegative =
      debt?.debtIsNegative || debt?.partner?.providerHasNegative;

    addGtagEvent(event);

    const eventName = debtEventName[event];
    const storedDebtsItem = localStorage.getItem(eventName);
    const storedDebtsEvent: StoredDebtsEvent = storedDebtsItem
      ? JSON.parse(legacyBase64Decrypt(storedDebtsItem))
      : {};

    const shouldSendEvent = hasSentDebtEvent(debtId, storedDebtsEvent);

    if (shouldSendEvent) {
      const flow = window?.location?.pathname?.replace('/', '');
      const payload = {
        dividaId: partnerId,
        divida_negativada_bvs: bvsNegative?.toString(),
        credor: partnerIdentifier,
        fluxo: flow,
      };
      if (event === 'simulationDone') {
        trackSimulationDone(payload);
      } else if (event === 'debtViewed') {
        trackDebtViewed(payload);
      } else if (event === 'debtFound') {
        trackDebtFound(payload);
      }
      sendEngageTrackEvent(event, debt);

      addDebtToStorage(storedDebtsEvent, debtId, partnerIdentifier, eventName);
    }
  };

  return {
    sendEvent,
    debtEventName,
  };
};

export default useTrackDebtCarousel;
