import { Redline } from 'redline-client-sdk';
import { RLSortingHatEvents } from '../types';
import { pixel } from './pixel';

export const sortingHatEvents = (rl: Redline): RLSortingHatEvents => ({
  decisionEnriched: (payload = {}) => {
    const event = 'sortingHat.DecisionEnriched.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
  },
  decisionOutcomeReceived: (payload = {}) => {
    const event = 'sortingHat.DecisionOutcomeReceived.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
  },
  decisionRequested: (payload = {}) => {
    const event = 'sortingHat.DecisionRequested.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
  },
  decisionAccepted: (payload = {}) => {
    const event = 'sortingHat.DecisionAccepted.v1';
    rl.track(event, { event, ...payload });
  },
  decisionDenied: (payload = {}) => {
    const event = 'sortingHat.DecisionDenied.v1';
    rl.track(event, { event, ...payload });
  },
  modalViewed: (payload = {}) => {
    const event = 'sortingHat.ModalViewed.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
  },
});
