import { maskCpf } from '@libs/utils/masks/cpf';
import { maskCellphone } from '@libs/utils/masks/cellphone';
import { ApiFireboltSoftPayloadProps } from './types';
import { maskBirthdate } from '@libs/utils/masks/birthdate';

export const transformFireboltSoftProposalPayload = (
  {item, metadata}: ApiFireboltSoftPayloadProps
) => ({
  item: {
    cpf: maskCpf(item?.cpf),
    full_name: item?.full_name,
    email: item?.email,
    main_phone: maskCellphone(item?.main_phone),
    date_of_birth: maskBirthdate(item?.date_of_birth),
    choosen_card: item?.choosen_card,
    issuer: item?.issuer,
    product_slug: item?.product_slug,
    partner: 'ac',
    application: 'ac'
  },
  metadata: {
    ...metadata,
  },
});
