import acInterceptor from '../acInterceptor';

import { useRequest } from 'alova';
import {
  transformDecidePayload,
  transformTrackPayload,
} from './transformPayload';

import { SortingHatReqProps, SortingHatDecideResponseProps } from './types';

const baseURL = import.meta.env.VITE_SORTING_HAT_API_URL;
const API_KEY = import.meta.env.VITE_SORTING_HAT_API_KEY;

const headers = {
  'Content-Type': 'application/json;'
};

const useApiSortingHat = () => {
  const apiSortingHatTrack = useRequest(
    (payload: SortingHatReqProps) =>
      acInterceptor({
        baseURL,
        headersBeforeRequest: { "x-api-key": API_KEY },
        addXAuthToken: false
      }
      ).Post('/track', transformTrackPayload(payload), {
        headers: headers,
      }),
    {
      immediate: false,
    }
  );

  const apiSortingHatDecide = useRequest(
    (payload: SortingHatReqProps) =>
      acInterceptor({
        baseURL,
        headersBeforeRequest: { "x-api-key": API_KEY },
        addXAuthToken: false
      }
      ).Post<SortingHatDecideResponseProps>(
        '/decide',
        transformDecidePayload(payload),
        {
          headers: headers,
        }
      ),
    {
      immediate: false,
    }
  );

  return { apiSortingHatTrack, apiSortingHatDecide };
};

export default useApiSortingHat;
