import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useNotifications() {
  const [notifications, setNotifications] = useState<Record<string, any>[]>([]);
  const [notificationsAlert, setNotificationsAlert] = useState(false);
  const [notificationsLength, setNotificationsLength] = useState<number>(0);
  const [notificationsLoader, setNotificationsLoader] = useState(false);
  const [toggleNotifications, setToggleNotifications] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // request notifications
  }, []);

  function onClickOpenNotifications() {
    setToggleNotifications(!toggleNotifications);
  }

  const onClickNotification =
    (url: string) => async (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      if (url) {
        navigate(`/${url}`);
      }
    };

  return {
    notifications,
    notificationsAlert,
    notificationsLoader,
    notificationsLength,
    onClickOpenNotifications,
    toggleNotifications,
    onClickNotification,
  };
}
