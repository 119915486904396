import untilLoad from "./untilLoad";

interface AddToHotjarProps {
  customerIdHash?: string,
  anonymousId?: string,

  sessionExperiments?: string,
  sessionVariants?: string,

  partnerIdentifier?: string
}


/**
 * Adds identification information and session variables to Hotjar for behavior analysis.
 * @param {string} [props.customerIdHash] - Customer identification hash.
 * @param {string} [props.anonymousId] - Anonymous user ID.
 * @param {string} [props.sessionExperiments] - Session experiments.
 * @param {string} [props.sessionVariants] - Session variants.
 * @param {string} [props.partnerIdentifier] - Partner identifier.
 */
export async function addToHotjar({
  customerIdHash,
  anonymousId,

  sessionExperiments,
  sessionVariants,

  partnerIdentifier
}: AddToHotjarProps) {
  const hj = await untilLoad(() => (window as any).hj);

  if (!hj) return console.warn("[hj - addToHotjar] is not defined.");

  if (hj) {
    const identifyData = {
      ...(!!customerIdHash && { customer_id_hash: customerIdHash }),
      ...(!!anonymousId && { anonymous_id: anonymousId }),

      ...(!!sessionExperiments && { session_experiments: sessionExperiments }),
      ...(!!sessionVariants && { session_variants: sessionVariants }),

      ...(!!partnerIdentifier && { partner_identifier: partnerIdentifier })
    };

    const userId = anonymousId || `${Math.random() * 9.99}|identify|${Math.random() * 999}|default`;
    hj("identify", userId, identifyData);
  }
}

/**
 * Tracks an event on Hotjar for behavior analysis.
 * @param {string} eventName - Name of the event to track.
 */
export async function trackEventHotjar(eventName: string) {
  const hj = await untilLoad(() => (window as any).hj);

  if (!hj) return console.warn("[hj - trackEventHotjar] is not defined.");

  if (hj) {
    hj("event", eventName)
  }
}
