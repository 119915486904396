export function getDevice() {
  const userAgent = window.navigator.userAgent;

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  if(/iPad|iPhone|iPod/i.test(userAgent)) {
    return 'ios'
  }

  return 'web';
}
