import { Link } from 'react-router-dom';
import Text from '../Text';
import {
  IconCreditCard,
  IconMail,
  IconMoney,
  IconVelocimeter,
} from '../icons/default';
import classNames from 'classnames';

interface NotificationsListProps {
  notifications: Record<string, any>[];
  onClickNotification: (url: string) => (event: any) => void;
}

interface ComponentTypeProps {
  color: string;
}

interface IconTypeProps {
  [key: string]: React.ComponentType<ComponentTypeProps>;
}

const NotificationsList = ({
  notifications,
  onClickNotification,
}: NotificationsListProps) => {
  return notifications.map((notification) => {
    const IconsType: IconTypeProps = {
      'email-icon': IconMail,
      'offer-card-icon': IconMail,
      'billet-expired-icon': IconMail,
      'billet-days-expire-icon': IconMail,
      'billet-one-day-expire-icon': IconMail,
      'billet-today-expire-icon': IconMail,
      'billet-paid-icon': IconMail,
      'score-icon': IconVelocimeter,
      'card-icon': IconCreditCard,
      'money-icon': IconMoney,
    };

    const IconComponent = IconsType[notification.icon];

    const linkClasses = classNames('notifications-bar__link', {
      'is-unread': !notification.read,
    });

    return (
      <Link
        key={notification.id}
        to={notification.urlDestiny}
        className={linkClasses}
        onClick={onClickNotification(notification.urlDestiny)}
      >
        <div className="notifications-bar__icon">
          <IconComponent color="dark" />
        </div>
        <Text as="span" color="common" variant="body-large" weight="bold">
          {notification.title}
        </Text>
        <Text
          as="span"
          color="common"
          variant="body-large"
          weight="regular"
          className="notifications-bar__message"
        >
          {notification.message}
        </Text>

        <Text as="span" color="common" variant="body-small" weight="regular">
          {new Date(notification.sendDate).toLocaleDateString('pt-BR')}
        </Text>
      </Link>
    );
  });
};

export default NotificationsList;
