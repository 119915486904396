import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import throttle from '@libs/utils/helpers/throttle';
import { useDeviceSize } from '@libs/utils/hooks/useDeviceSize';
import { useTracking } from '@libs/tracking';
import { removeCookie } from '@libs/utils/helpers/getSafeCookies';
import { addCookie, getCookie } from '@libs/utils/helpers/getSafeCookies';

import constants from '@common/constants';
import { useHidePageTemplate } from '@common/store/useHidePageTemplate';
import { useUserStore } from '@common/store/useUserStore';
import useNotifications from '@common/hooks/useNotifications';
import { OfferReminder } from '@common/hooks/useOffers/types';
import useAuth from '@common/hooks/useAuth';
import { pageNames } from '@common/routes/pageNames';
import useTrackOffer from '@common/hooks/useTrackOffer';

const pathConfig = {
  noFullHeader: ['minhas-dividas'],
  arrowBack: [
    '/confirmacao',
    '/ofertas',
    '/monitoramento/financeiro',
    '/condicoes',
    '/composicao',
    '/acordo/',
    '/acordo-fechado',
  ],
  partnerLogo: ["confirmacao", "acordo-fechado"],
  hideMenu: ["confirmacao", "acordo-fechado"],
  hideNotificationsAlert: ["confirmacao", "acordo-fechado"],
  hideFooter: ["confirmacao"],
};

const usePrivatePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logOut } = useAuth();
  const { trackElementClicked } = useTrackOffer();

  const user = useUserStore((state) => state.user);
  const { addUser: addUserStore } = useUserStore();

  const [scrolled, setScrolled] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  const [reminders, setReminders] = useState<Record<string, any>[]>([]);
  const [remindersLength, setRemindersLength] = useState(0);

  const validatePath = (paths: string[]) =>
    paths.some((path) => location.pathname.includes(path));

  const handlePrivPageConfig = () => {
    return {
      fullHeader: !validatePath(pathConfig.noFullHeader),
      partnerLogo: validatePath(pathConfig.partnerLogo),
      arrowBack: validatePath(pathConfig.arrowBack),
      menu: !validatePath(pathConfig.hideMenu),
      notificationsAlert: !validatePath(pathConfig.hideNotificationsAlert),

      footer: !validatePath(pathConfig.hideFooter),
    };
  };

  const [privPageConfig, setPrivPageConfig] = useState(handlePrivPageConfig);

  const { eventNames, track } = useTracking();
  const { isMobile } = useDeviceSize();
  const { shouldHidePageTemplate } = useHidePageTemplate();
  const { score: scoreStore } = useUserStore((state) => state);
  const {
    notifications,
    notificationsAlert,
    notificationsLoader,
    notificationsLength,
    toggleNotifications,
    onClickOpenNotifications,
    onClickNotification,
  } = useNotifications();

  const score = scoreStore.current?.score || 0;

  const privatePageClasses = classNames('private-page', {
    'is-scrolled': scrolled,
  });

  const handleScroll = throttle(
    () => (window.scrollY > 30 ? setScrolled(true) : setScrolled(false)),
    200
  );

  const onClickMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  const onClickOfferLink = (offer: OfferReminder) => {
    if (offer.urlDestiny !== '/ofertas') {
      track(eventNames.LeadDirecionado, {
        destino: `${offer.eventConstant}-pre-approved`,
        category: 'notificacoes',
      });

      window.open(offer.urlDestiny, '_blank');
    } else {
      navigate(offer.urlDestiny);
    }
  };

  useEffect(() => setPrivPageConfig(handlePrivPageConfig), [location]);

  useEffect(() => {
    setReminders(notifications);
    setRemindersLength(notificationsLength);
  }, [notifications, notificationsLength, score]);

  useEffect(() => {
    // TODO: Remove after migration
    const cookieSubject = getCookie('SUBJECT', false);

    if (cookieSubject) {
      const decodeSubject = window.atob(String(cookieSubject));
      const parseSubject = JSON.parse(decodeSubject);

      const isValidAdminData =
        user.documento !== parseSubject.documento && parseSubject.admin;
      if (isValidAdminData) addUserStore(parseSubject);
    }

    if (!isMobile) window.addEventListener('scroll', handleScroll);
  }, []);

  const onLogout = () => {
    trackElementClicked({
      name: "sair",
      location: "ac-logout-modal",
      elementType: "button",
    });

    logOut()
    removeCookie('rl_ctrl_debts_react');
    indexedDB.deleteDatabase('ac');
    return window.location.href = pageNames.signin.path;
  }

  const [showLogoutModal, setShowLogoutModal] = useState(false)

  const openLogoutModal = () => {
    setShowLogoutModal(true)
  }

  const closeLogoutModal = () => {
    setShowLogoutModal(false)
  }

  return {
    privatePageClasses,
    shouldHidePageTemplate,
    isMobile,
    onClickMenu,
    onClickOpenNotifications,
    notificationsAlert,
    toggleMenu,
    notifications: reminders,
    notificationsLength: remindersLength,
    notificationsLoader,
    toggleNotifications,
    onClickNotification,
    onClickOfferLink,

    privPageConfig,

    showLogoutModal,
    openLogoutModal,
    closeLogoutModal,
    onLogout,
  };
};

export default usePrivatePage;
