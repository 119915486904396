// import { useEffect } from 'react';
// import { startIndicative } from './startIndicative';
import useTrack from './useTrack';
import useEvents from './useEvents';
import { useConstants } from './useConstants';

declare global {
  interface Window {
    Indicative: {
      buildEvent(name: string, params?: Record<string, any>): void;
    };
  }
}

export default function useTrackingProvider() {
  const track = useTrack();
  const eventNames = useConstants();
  const trackEvents = useEvents({ track, eventNames });


  return {
    track,
    eventNames,
    ...trackEvents,
  };
}
