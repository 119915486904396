import React, { useState, useEffect, useRef, useCallback } from 'react';

interface LazyImageTypes {
  className?: string;
  lazy?: boolean;
  src: string;
  alt?: string;
  height?: number;
  width?: number;
  draggable?: boolean;
  style?: {[key: string]: string | number}
}

const LazyImage = ({
  className,
  lazy = true,
  src,
  alt,
  height,
  width,
  draggable = false,
  style
}: LazyImageTypes) => {
  const [imageSrc, setImageSrc] = useState<string | undefined>(
    lazy ? undefined : src
  );
  const imgRef = useRef<any>(null);

  const observeIntersection = useCallback(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setImageSrc(src);

          if (imgRef.current) observer.unobserve(imgRef.current);
        }
      });
    });

    if (imgRef.current) observer.observe(imgRef.current);

    return () => {
      if (observer && imgRef.current) observer.disconnect();
    };
  }, [src]);

  useEffect(() => {
    if(src !== imageSrc && !lazy) setImageSrc(src);

    const cleanupObserver = lazy ? observeIntersection() : () => {};

    return () => cleanupObserver();
  }, [observeIntersection, lazy]);

  return React.createElement('img', {
    ...(lazy && { ref: imgRef }),

    className,
    ...(lazy && { loading: 'lazy' }),
    src: imageSrc,
    alt,
    height,
    width,
    draggable,
    style
  });
};

export default LazyImage;
