import { checkCookie, getCookie } from '@libs/utils/helpers/getSafeCookies'

export const getMood = (): any => {
  const mood = (window as any).mood;
  if (typeof mood === 'undefined') {
    console.log('[mood] is not defined.');
  }
  return mood;
};

export const getObjectFromCookie = (cookieName: string): any => {
  const hasCookie = checkCookie(cookieName);
  const getCookieValue = getCookie(cookieName, false);
  return hasCookie && getCookieValue && JSON.parse(getCookieValue);
};

export const getUtm = (): any => {
  const mood = getMood();
  return mood ? mood.getUtm() : getObjectFromCookie('mood_utm');
};

export const getSession = (): any => {
  const mood = getMood();
  return mood ? mood.getSession() : getObjectFromCookie('mood_session');
};

export const getGclid = (): any => {
  const mood = getMood();
  return mood ? mood.getGclid() : getObjectFromCookie('mood_gclid');
};
