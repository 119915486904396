import { Router } from '@common/routes/Router';
import { useRedline } from '@libs/redline';
import { getAnonymousID } from 'redline-client-sdk';
import { addToHotjar } from '@libs/utils/helpers/addToHotjar';
import {
  addCookie,
  getCookie,
  getCookiesStartsWith,
} from '@libs/utils/helpers/getSafeCookies';
import { isProd } from '@libs/utils/helpers/isProd';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import '@libs/atlas/styles/GlobalStyles.scss';
import 'react-spinetic/styles.css';
import { useUserStore } from '@common/store/useUserStore';

function App() {
  const { track } = useRedline();
  const anonymousId = getAnonymousID();
  const user = useUserStore((state) => state.user);

  function sendEventExperimentsDogx() {
    const dxCookies = getCookiesStartsWith('dx-');
    const { 'dx-bg': removedProp, ...dxCookiesOnly } = dxCookies;
    const hasTest = Object.keys(dxCookiesOnly).length;

    if (hasTest) {
      const experiments: string[] = [];
      const experimentsResults: string[] = [];

      const dxExperiments = Object.keys(dxCookiesOnly)
        .map((name) => {
          const dxValues = dxCookiesOnly[name]?.split(':');
          if (!dxValues) return null;

          const [variant, version, percentage] = dxValues;

          experiments.push(name);
          experimentsResults.push(variant);

          return {
            name,
            variant,
            version: Number(version),
            percentage: Number(percentage),
          };
        })
        .filter((experiment) => experiment !== null);

      track.dogx.experimentsLoaded({
        experiments: dxExperiments,
      });

      addToHotjar({
        ...(!!user.customerIdHash && { customerIdHash: user.customerIdHash }),
        anonymousId: anonymousId,

        sessionExperiments: experiments.join('|').slice(0, 199),
        sessionVariants: experimentsResults.join('|').slice(0, 199),
      });
    } else {
      addToHotjar({
        ...(!!user.customerIdHash && { customerIdHash: user.customerIdHash }),
        anonymousId: anonymousId,
      });
    }
  }

  useEffect(() => {
    const startSourceUrl = getCookie('start_source_url');
    if (!startSourceUrl) {
      addCookie({
        key: 'start_source_url',
        value: window.location.href,
      });
    }

    sendEventExperimentsDogx();
  }, []);

  useEffect(() => {
    if (!!window?.hj) {
      const currentCustomerId = user?.customerIdHash;

      const hjUserAttributes = window?.hj?.userAttributes;
      const hjAttributes = hjUserAttributes?.attributes;

      const hjUserId = hjUserAttributes?.id;
      const hjCustomerId = hjAttributes?.customer_id_hash;

      if (!!currentCustomerId && !hjCustomerId && !!hjUserId) {
        hjUserAttributes.set(hjUserId, {
          customer_id_hash: user.customerIdHash,
        });
      }

      if (!currentCustomerId && hjCustomerId && !!hjUserId) {
        hjUserAttributes.set(hjUserId, {
          customer_id_hash: '',
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (isProd()) {
      const appVersion = import.meta.env.VITE_DEPLOY_VERSION;

      window.__APPVERSION__ = appVersion;

      addCookie({
        key: 'appVersion',
        value: appVersion,
      });
    }
  }, []);

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

export default App;
