import { Link } from 'react-router-dom';
import classNames from 'classnames';
import avatar from '@libs/atlas/assets/images/webp/avatar.webp';
import ACLogo from '../../assets/images/ac-full-logo.svg';

import {
  IconCheckCircle,
  IconChevronRight,
  IconGiveMoney,
  IconHelpCircle,
  IconLogOut,
  IconNegotiation,
  IconUser,
  IconVelocimeter,
  IconX,
} from '../icons/default';
import Text from '../Text';
import Img from '../Img';
import LazyImage from '../LazyImage';

import './styles.scss';

interface NavBarProps {
  firstName: string | undefined;
  toggleMenu: boolean;
  onClose: () => void;
  onLogout: () => void;
}

const NavBar = ({ firstName, toggleMenu, onClose, onLogout }: NavBarProps) => {
  const navbarClasses = classNames('navbar', {
    'is-active': toggleMenu,
  });

  return (
    <nav className={navbarClasses} onClick={onClose}>
      <div className="navbar__menu">
        <div className="navbar__header">
          <div className="navbar__actions">
            <LazyImage
              src={ACLogo}
              lazy={false}
              alt="Logotipo Acordo Certo"
              width={200}
              height={29}
            />

            <button onClick={onClose} className="navbar__actions-close" aria-label="Fechar menu">
              <IconX />
            </button>
          </div>
          <div className="navbar__profile">
            <div className="navbar__profile-image">
              <Img
                src={avatar}
                alt="Imagem de perfil padrão"
                width={80}
                height={80}
              />
            </div>
            <Text as="h2" color="principal" variant="heading-three">
              Olá, {firstName}
            </Text>
          </div>
        </div>
        <div className="navbar__links">
          <Link to={'/minhas-dividas'} className="navbar__link">
            <IconNegotiation />
            <Text
              as="span"
              color="principal"
              variant="heading-four"
              weight="regular"
            >
              Negociar dívidas
            </Text>
            <Text as="span" variant="body-large" weight="regular">
              Consulte dívidas e negocie
            </Text>
            <IconChevronRight />
          </Link>
          <Link to={'/meus-acordos'} className="navbar__link">
            <IconCheckCircle />
            <Text
              as="span"
              color="principal"
              variant="heading-four"
              weight="regular"
            >
              Meus acordos
            </Text>
            <Text as="span" variant="body-large" weight="regular">
              Visualize seus acordos e boletos
            </Text>
            <IconChevronRight />
          </Link>
          <Link to={'/ofertas'} className="navbar__link">
            <IconGiveMoney />
            <Text
              as="span"
              color="principal"
              variant="heading-four"
              weight="regular"
            >
              Ofertas para você
            </Text>
            <Text as="span" variant="body-large" weight="regular">
              Vantagens para mudar sua vida financeira
            </Text>
            <IconChevronRight />
          </Link>
          {/* TODO: replace with Link after monitoring migration */}
          <a href={'/monitoramento/financeiro'} className="navbar__link">
            <IconVelocimeter />
            <Text
              as="span"
              color="principal"
              variant="heading-four"
              weight="regular"
            >
              Monitorar meu CPF
            </Text>
            <Text as="span" variant="body-large" weight="regular">
              Acompanhe seu CPF e Score de crédito
            </Text>
            <IconChevronRight />
          </a>
          <Link to={'/minha-conta'} className="navbar__link">
            <IconUser />
            <Text
              as="span"
              color="principal"
              variant="heading-four"
              weight="regular"
            >
              Minha conta
            </Text>
            <Text as="span" variant="body-large" weight="regular">
              Visualize e edite suas informações
            </Text>
            <IconChevronRight />
          </Link>
          <a
            href={'https://ajuda.acordocerto.com.br/hc/pt-br'}
            className="navbar__link"
          >
            <IconHelpCircle />
            <Text
              as="span"
              color="principal"
              variant="heading-four"
              weight="regular"
            >
              Central de ajuda
            </Text>
            <Text as="span" variant="body-large" weight="regular">
              Tire suas dúvidas
            </Text>
            <IconChevronRight />
          </a>
          <Link to="#" onClick={onLogout} className="navbar__link">
            <IconLogOut />
            <Text
              as="span"
              color="principal"
              variant="heading-four"
              weight="regular"
            >
              Sair
            </Text>
            <IconChevronRight />
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
