import { ReactNode, lazy } from 'react';
import usePrivatePage from './hooks';

import Conditional from '@libs/atlas/components/misc/Conditional';
import PrivateHeader from '@libs/atlas/components/PrivateHeader';
import NavBar from '@libs/atlas/components/NavBar';
import NotificationsBar from '@libs/atlas/components/NotificationsBar';

import './styles.scss';

const Footer = lazy(() => import('@common/components/Footer'));

type PrivatePageTemplateProps = {
  children: ReactNode;
  firstName: string | undefined;
};

export default function PrivatePageTemplate({
  children,
  firstName,
}: PrivatePageTemplateProps) {
  const {
    privatePageClasses,
    shouldHidePageTemplate,
    onClickMenu,
    onClickOpenNotifications,
    notificationsAlert,
    toggleMenu,
    notifications,
    notificationsLength,
    notificationsLoader,
    toggleNotifications,
    onClickNotification,
    privPageConfig,
    showLogoutModal,
    openLogoutModal,
    closeLogoutModal,
    onLogout,
  } = usePrivatePage();

  return (
    <div className={privatePageClasses}>
      <Conditional
        condition={!shouldHidePageTemplate}
        renderIf={
          <div className="private-page__container">
            <PrivateHeader
              fullHeader={privPageConfig.fullHeader}
              arrowBack={privPageConfig.arrowBack}
              logoPartner={privPageConfig.partnerLogo}
              menu={privPageConfig.menu}
              onClickMenu={onClickMenu}
              onClickOpenNotifications={onClickOpenNotifications}
              notificationsAlert={privPageConfig.notificationsAlert}
            />
            <NavBar
              firstName={firstName}
              toggleMenu={toggleMenu}
              onClose={onClickMenu}
              onLogout={onLogout}
            />

            <NotificationsBar
              notifications={notifications}
              notificationsLength={notificationsLength}
              notificationsLoader={notificationsLoader}
              toggleNotifications={toggleNotifications}
              onClickNotification={onClickNotification}
              onClose={onClickOpenNotifications}
            />
          </div>
        }
      />

      <div className="private-page__container">{children}</div>

      <Conditional
        condition={!shouldHidePageTemplate && privPageConfig.footer}
        renderIf={<Footer />}
      />
    </div>
  );
}
