import { UseConstantsResponse } from '../types';

export const useConstants = (): UseConstantsResponse => ({
  Home: 'Home',
  PageView: 'Page View',
  Simulation: 'Simulação',
  Register: 'Cadastro',
  UserWithRegister: 'Usuário com cadastro',
  UserWithoutRegister: 'Usuário sem cadastro',
  LoginWithSuccess: 'Login com sucesso',
  RegisterWithSuccess: 'Cadastro com sucesso',
  Logon: 'Logon',
  Experiment: 'Experiment',
  LeadDirecionado: 'leaddirecionado',

  ProductViewed: 'Produto Visualizado',
  ProductClicked: 'Produto Clicado',

  DebtFound: 'Dívida localizada',
  SimulationDone: 'Simulacao Realizada',
  DebtVisualized: 'Dívida visualizada',
  DebtViewed: 'Dívida Exibida',

  Checkout: 'Checkout',
  DebtSelectedOption: 'Dívida visualizada - Opção selecionada',
  Agreement: 'Acordo',
  AgreementP4: 'Acordo p4',
  GenerateBillet: 'generateBillet',
  PixGenerated: 'Pix Gerado',
  ErrorPix: 'errorPix',
  PixPayed: 'Pix Pago',
});
