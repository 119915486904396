import { createContext } from 'react';
import { TrackingContextProps, TrackingProviderProps } from '../../types';
import useTrackingProvider from '../../hooks/useTrackingProvider';

export const TrackingContext = createContext<TrackingContextProps | undefined>(
  undefined
);

export const TrackingProvider = ({
  children,
}: TrackingProviderProps): JSX.Element => {
  const useTrackProps = useTrackingProvider()

  return (
    <TrackingContext.Provider value={useTrackProps}>
      {children}
    </TrackingContext.Provider>
  );
};
