import classNames from 'classnames';

import { ReactComponent as ACLogo } from '../../assets/images/ac-logo.svg';
import { ReactComponent as ACFullLogo } from '../../assets/images/ac-full-logo.svg';

import { IconBell, IconChevronLeft, IconMenu } from '../icons/default';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Conditional from '../misc/Conditional';
import { getPartnerImage } from '@libs/utils/helpers/getPartnerImage';

import './styles.scss';

type PrivateHeaderProps = {
  fullHeader?: boolean;
  arrowBack?: boolean;
  fullLogo?: boolean;
  logoPartner?: boolean;
  menu?: boolean;

  onClickMenu: () => void;

  onClickOpenNotifications: () => void;
  notificationsAlert: boolean;
  fixed?: boolean;
};

const PrivateHeader = ({
  fullHeader = false,
  arrowBack = true,
  fullLogo = false,
  logoPartner = false,
  menu = true,

  onClickMenu,

  notificationsAlert,
  onClickOpenNotifications,

  fixed = true,
}: PrivateHeaderProps) => {
    // const navigate = useNavigate(); // TODO

  const clickGoBack = () => {
    const currentPath = window.location.pathname;

    if (currentPath.includes('confirmacao')) return;

    // if (currentPath.includes('acordo-fechado')) return navigate('/minhas-dividas'); // TODO
    if (currentPath.includes('acordo-fechado')) return window.location.href = 'minhas-dividas';

    // return navigate(-1); // TODO
    return window?.history.go(-1);
  };

  const headerClasses = classNames('header', {
    'header--dash': !fullHeader,
    'header--fixed': fixed,
    'header--full': fullHeader,
  });

  const buttonNotificationsClasses = classNames(
    'header__button header__button--notification',
    {
      'is-alert': notificationsAlert,
    }
  );

  const params = useParams();
  const partnerImage = getPartnerImage(params?.partner);

  return (
    <header className={headerClasses}>
      <div className="header__container">
        <div className="header__wrap">
          <Conditional
            condition={arrowBack}
            renderIf={
              <button
                id="arrow_back_private_header"
                onClick={clickGoBack}
                className="header__back"
                aria-label="Voltar para página anterior"
              >
                <IconChevronLeft />
              </button>
            }
          />
          <Conditional
            condition={fullLogo}
            renderIf={
              <h1 className="header__full-logo">
                {/* <Link // TODO: error when trying to navigate to my debts, replace with href.
                  to={'/minhas-dividas'}
                  aria-label="Ir para a página inicial"
                >
                  <ACFullLogo />
                </Link> */}

                <a
                  href={'/minhas-dividas'}
                  aria-label="Ir para a página inicial"
                >
                  <ACFullLogo />
                </a>
              </h1>
            }
            renderElse={
              <Conditional
                condition={!logoPartner}
                renderIf={
                  <h1 className="header__logo">
                    {/* <Link // TODO: error when trying to navigate to my debts, replace with href.
                      to={'/minhas-dividas'}
                      aria-label="Ir para a página inicial"
                    >
                      <ACLogo />
                    </Link> */}

                    <a
                      href={'/minhas-dividas'}
                      aria-label="Ir para a página inicial"
                    >
                      <ACLogo />
                    </a>
                  </h1>
                }
              />
            }
          />
        </div>
        <div className="header__wrap">
          {logoPartner && params?.partner !== "btg" && (
            <>
              <h1 className="header__logo">
                {/* <Link // TODO: error when trying to navigate to my debts, replace with href.
                  to={'/minhas-dividas'}
                  aria-label="Ir para a página inicial"
                >
                  <ACLogo />
                </Link> */}

                <a
                  href={'/minhas-dividas'}
                  aria-label="Ir para a página inicial"
                >
                  <ACLogo />
                </a>
              </h1>
              <img
                className="header__logo-partner"
                src={partnerImage}
                alt={`Logo ${params.partner}`}
              />
            </>
          )}
          <div className="header__buttons">
            <Conditional
              condition={notificationsAlert}
              renderIf={
                <button
                  className={buttonNotificationsClasses}
                  onClick={onClickOpenNotifications}
                  aria-label="Acessar menu de notificações"
                >
                  <IconBell />
                </button>
              }
            />
            {menu && (
              <button
                className="header__button header__button--menu"
                onClick={onClickMenu}
                aria-label="Acessar menu"
              >
                <IconMenu />
              </button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default PrivateHeader;
