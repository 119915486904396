import acInterceptor from '../acInterceptor';
import { useRequest } from 'alova';
import { IFireboltApiSoftResponse } from './types';
import { transformFireboltSoftProposalPayload } from './transformPayload';

const baseURL = import.meta.env.VITE_FIREBOLT_API_URL;
const headers = {
  'Content-Type': 'application/json;',
};

const useApiFirebolt = () => {
  const apiFireboltForm = useRequest(
    acInterceptor({ baseURL, addXAuthToken: false }).Get<any>('/form/iq-marketplace', {
      headers: {
        ...headers,
      },
    }),
    {
      immediate: false,
    }
  );

  const sendSoftProposal = useRequest(
    (partnerSlug, payload) =>
      acInterceptor({ baseURL, addXAuthToken: false }).Post<IFireboltApiSoftResponse<string[]>>(
        `/proposal/soft/${partnerSlug ?? 'default'}`,
        {
          ...transformFireboltSoftProposalPayload(payload),
        },
        {
          headers,
        }
      ),
    {
      immediate: false,
    }
  );

  return {
    apiFireboltForm,
    sendSoftProposal,
  };
};

export default useApiFirebolt;
