import { useEffect, useState } from 'react';
import {
  pageNames,
  privatePagePaths,
  publicPagePaths,
} from '@common/routes/pageNames';

type UseTitleProps = {
  titlePage: string;
  setCurrentPath: React.Dispatch<React.SetStateAction<string>>;
};

export default function useTitle(): UseTitleProps {
  const [titlePage, setTitlePage] = useState<string>('');
  const [currentPath, setCurrentPath] = useState<string>(
    window.location.pathname
  );

  useEffect(() => {
    const notFoundPath = '/pagina-nao-encontrada';
    const pageNameList = Object.keys(pageNames);
    
    const pageIndex = Object.values(pageNames).findIndex((page) => {
      const regexPattern = page.path.replace(/:[^\s/]+/g, '[^/]+');
      const regex = new RegExp(`^${regexPattern}$`);
      return regex.test(currentPath) || regex.test(notFoundPath);
    });

    const pageClass = `page-${pageNameList[pageIndex]}`.toLocaleLowerCase();
    const pageName = pageNames[pageNameList[pageIndex]]?.name;
    const pagePaths = [...publicPagePaths, ...privatePagePaths];

    const currentPathPage =
      currentPath.charAt(currentPath.length - 1) === '/'
        ? currentPath.substring(0, currentPath.length - 1)
        : currentPath;
    if (
      pagePaths.includes(currentPathPage) ||
      pagePaths.includes(notFoundPath)
    ) {
      document.body.classList.add(pageClass);
      setTitlePage(`${pageName} -`);
    }

    return () => {
      document.body.classList.remove(pageClass);
    };
  }, [currentPath]);

  return {
    titlePage,
    setCurrentPath,
  };
}
