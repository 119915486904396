import acInterceptor from '../acInterceptor';
import { useRequest } from 'alova';

import {
  NegativeDebtResponse,
  ScoreResponseProps,
  ScoreHistoric,
  MonitoringHistoricResponseProps,
  ScoreDetails,
} from '@common/apis/financial-data/types';

const baseURL = import.meta.env.VITE_FINANCIAL_DATA_API_URL
const renegAppKey = import.meta.env.VITE_RENEG_APP_KEY

const useApiFinancialData = () => {
  const apiFinancialDataScore = useRequest(
    acInterceptor({ baseURL })
      .Get<ScoreResponseProps>('/api/v2/score'),
    { immediate: false },
  );

  const apiFinancialDataScoreHistory = useRequest(
    acInterceptor({ baseURL })
      .Get<ScoreHistoric[]>('/api/v2/score/history'),
    { immediate: false },
  );

  const apiFinancialDataScoreDetails = useRequest(
    acInterceptor({ baseURL, headersBeforeRequest: { renegAppKey } })
      .Get<ScoreDetails>('/api/v1/score/details'),
    { immediate: false },
  )

  const apiFinancialDataMonitoring = useRequest(
    acInterceptor({ baseURL })
      .Get<MonitoringHistoricResponseProps>('/api/v1/monitoring'),
    { immediate: false },
  );

  const apiFinancialDataNegativeDebts = useRequest(
    acInterceptor({ baseURL })
      .Get<NegativeDebtResponse>('/api/v1/negative'),
    { immediate: false },
  );

  return {
    apiFinancialDataMonitoring,
    apiFinancialDataNegativeDebts,
    apiFinancialDataScore,
    apiFinancialDataScoreHistory,
    apiFinancialDataScoreDetails
  }
};

export default useApiFinancialData;
