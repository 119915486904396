import { SortingHatReqProps } from './types';
import { base64Encrypt } from '@libs/utils/helpers/base64'
import { getFbAvailableCookies } from '@libs/utils/helpers/getFacebookPixel';
import { getGAAvailableCookies } from '@libs/utils/helpers/getGoogleAnalyticsCookies';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';


export const transformTrackPayload = ({
  customerIdHash,
  document,
  badCredit,
  device,
  email,
  mainPhone,
  fullName,
  sessionId,
  cpfUfEmissor,
  ddd,
  rdg
}: SortingHatReqProps) => {
  return ({
  data: base64Encrypt(
    JSON.stringify({
      document: document,
      cpf: document,
      identifier: customerIdHash,
      anonymousId: customerIdHash,
      device,
      badCredit,
      email,
      mainPhone,
      fullName,
      sessionId,
      cpfUfEmissor,
      ddd,
      rdg,
      fbCookies: getFbAvailableCookies(),
      gaCookies: getGAAvailableCookies(),
      source_url: getCookie('start_source_url') || window?.location.href
    })
  ),
})};

export const transformDecidePayload = ({
  customerIdHash,
  document,
  device,
  tree = 'ac-tree-react',
}: SortingHatReqProps) => ({
  data: base64Encrypt(
    JSON.stringify({
      document: document,
      cpf: document,
      identifier: customerIdHash,
      anonymousId: customerIdHash,
      device,
      workflow_id: tree,
    })
  ),
});
