import { Redline } from 'redline-client-sdk';
import { RLDogxEvents } from '../types';
import { pixel } from './pixel';

export const dogxEvents= (rl: Redline): RLDogxEvents => ({
  experimentsLoaded: (payload = {}) => {
    const event = 'dogx.ExperimentsLoaded.v1';
    rl.track(event, { event, ...payload });
    pixel(event, { event, ...payload });
  },
});
