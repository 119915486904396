import { useState } from 'react';
import { UseEventProps, UseEventsProps } from '../types';

const useEvents = ({ track, eventNames }: UseEventsProps) => {
  const [isGoogleFlow] = useState(false);

  function flowRegister() {
    if (isGoogleFlow) return 'cadastro_social';
    return 'cadastro';
  }

  function flowLogin() {
    if (isGoogleFlow) return 'login_social';
    return 'login_padrao';
  }

  const trackEvents = {
    trackPageView(params?: UseEventProps) {
      track(eventNames[`PageView`], params);
    },
    trackHome(params?: UseEventProps) {
      const props: UseEventProps = {
        fluxo: 'login',
        usuario_autenticado: true,
        origem_consumidor: '',
        ...params,
      };
      track(eventNames[`Home`], props);
    },
  };
  const trackEventsForm = {
    trackRegisterWithSuccess(params?: UseEventProps) {
      const props: UseEventProps = {
        fluxo: flowRegister(),
        origem_consumidor: '',
        step_name: flowRegister(),
        ...params,
      };
      track(eventNames[`RegisterWithSuccess`], props);

      const propsLogon = Object.fromEntries(
        Object.entries(props).filter(
          ([key]) => !['step_name', 'usuario_autenticado'].includes(key)
        )
      );
      track(eventNames[`Logon`], propsLogon);
    },
    trackLoginWithSuccess(params?: UseEventProps) {
      const props: UseEventProps = {
        fluxo: 'login',
        origem_consumidor: '',
        step_name: flowLogin(),
        usuario_autenticado: true,
        ...params,
      };
      track(eventNames[`LoginWithSuccess`], props);

      const propsLogon = Object.fromEntries(
        Object.entries(props).filter(
          ([key]) => !['step_name', 'usuario_autenticado'].includes(key)
        )
      );
      track(eventNames[`Logon`], propsLogon);
    },
    trackUserWithRegister(params?: UseEventProps) {
      const props: UseEventProps = {
        fluxo: 'login',
        origem_consumidor: '',
        ...params,
      };
      track(eventNames[`UserWithRegister`], props);
    },
    trackUserWithoutRegister(params?: UseEventProps) {
      const props: UseEventProps = {
        fluxo: 'cadastro',
        ...params,
      };
      track(eventNames[`UserWithoutRegister`], props);
    },
  };
  const trackEventsClick = {
    trackClickRegisterSimulation(params?: UseEventProps) {
      const props: UseEventProps = {
        fluxo: 'cadastro',
        ...params,
      };

      track(eventNames[`Simulation`], props);
    },
    trackClickLoginSimulation(params?: UseEventProps) {
      const props: UseEventProps = {
        fluxo: 'login',
        ...params,
      };

      track(eventNames[`Simulation`], props);
    },
  };
  const trackEventsDebts = {
    trackDebtVisualized(params?: UseEventProps) {
      const props: UseEventProps = {
        ...params,
      };
      track(eventNames[`DebtVisualized`], props);
    },
    trackSimulationDone(params?: UseEventProps) {
      const props: UseEventProps = {
        ...params,
      };
      track(eventNames[`SimulationDone`], props);
    },
    trackDebtViewed(params?: UseEventProps) {
      const props: UseEventProps = {
        ...params,
      };
      track(eventNames[`DebtViewed`], props);
    },
    trackDebtFound(params?: UseEventProps) {
      const props: UseEventProps = {
        ...params,
      };
      track(eventNames[`DebtFound`], props);
    },
    trackDebtSelectedOption(params?: UseEventProps) {
      const props: UseEventProps = {
        ...params,
      };
      track(eventNames[`DebtSelectedOption`], props);
    },
    trackAgreement(params?: UseEventProps) {
      const props: UseEventProps = {
        ...params,
      };
      track(eventNames[`Agreement`], props);
    },
    trackAgreementP4(params?: UseEventProps) {
      const props: UseEventProps = {
        ...params,
      };
      track(eventNames[`AgreementP4`], props);
    },
    trackCheckout(params?: UseEventProps) {
      const props: UseEventProps = {
        ...params,
      };
      track(eventNames[`Checkout`], props);
    },
    trackPixPayed(params?: UseEventProps) {
      const props: UseEventProps = {
        ...params,
      };
      track(eventNames[`PixPayed`], props);
    },
    trackPixGenerated(params?: UseEventProps) {
      const props: UseEventProps = {
        ...params,
      };
      track(eventNames[`PixGenerated`], props);
    },
    trackErrorPix(params?: UseEventProps) {
      const props: UseEventProps = {
        ...params,
      };
      track(eventNames[`ErrorPix`], props);
    },
    trackGenerateBillet(params?: UseEventProps) {
      const props: UseEventProps = {
        ...params,
      };
      track(eventNames[`GenerateBillet`], props);
    },
  };

  return {
    ...trackEvents,
    ...trackEventsForm,
    ...trackEventsClick,
    ...trackEventsDebts,
  };
};

export default useEvents;
export type TrackingContextDynamicProps = typeof useEvents;
